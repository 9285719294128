import { useState, useEffect } from 'react';
import { isIOS, isAndroid } from 'react-device-detect';

import { useKeylessAccounts } from '~/hooks/useKeylessAccounts';

const useLoginMain = () => {
  const [loading, setLoading] = useState(false);
  const { activeAccount, disconnectKeylessAccount } = useKeylessAccounts();
  const originUrl = location.origin;

  useEffect(() => {
    if (activeAccount) {
      disconnectKeylessAccount();
    }
  }, [activeAccount, disconnectKeylessAccount]);

  const handleLoginClick = (provider: string) => {
    setLoading(true);
    localStorage.setItem('oauth_provider', provider);
    const url = `${originUrl}/start-login?provider=${provider}`;

    if (isIOS) {
      // iOS
      (window as any).webkit.messageHandlers.MilelogPublicApi_startLogin.postMessage({
        url,
      });
    } else if (isAndroid) {
      // Android
      (window as any).MilelogPublicApi.startLogin(url);
    } else {
      window.location.href = url;
    }
  };

  return { isIOS, handleLoginClick, loading };
};

export default useLoginMain;
