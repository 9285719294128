import { Routes, Route } from 'react-router-dom';

import AuthSuccessPage from './pages/AuthSuccessPage';
import CallbackPage from './pages/CallbackPage';
import LoginPage from './pages/LoginPage';
import MainPage from './pages/MainPage';
import StartLoginPage from './pages/StartLoginPage';
import TermsPrivacyPage from './pages/TermsPrivacyPage';

export const Router = () => {
  return (
    <Routes>
      <Route path="/home" element={<MainPage />} />
      <Route path="/login" element={<LoginPage />} />
      <Route path="/callback" element={<CallbackPage />} />
      <Route path="/auth/apple/callback" element={<CallbackPage />} />
      <Route path="/auth-success" element={<AuthSuccessPage />} />
      <Route path="/terms-privacy" element={<TermsPrivacyPage />} />
      <Route path="/start-login" element={<StartLoginPage />} />
      <Route path="/actuator/health" />
    </Routes>
  );
};
