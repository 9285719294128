import { useEffect, useMemo } from 'react';

import { createEphemeralKeyPair } from '~/core/ephemeral';
import { useKeylessAccounts } from '~/hooks/useKeylessAccounts';

const useStartLoginMain = () => {
  const queryParams = new URLSearchParams(window.location.search);
  const provider = queryParams.get('provider');

  const GOOGLE_CLIENT_ID = import.meta.env.VITE_GOOGLE_CLIENT_ID;
  const GOOGLE_AUTH_URL = import.meta.env.VITE_GOOGLE_AUTH_URL;
  const APPLE_CLIENT_ID = import.meta.env.VITE_APPLE_CLIENT_ID;
  const APPLE_AUTH_URL = import.meta.env.VITE_APPLE_AUTH_URL;
  const originUrl = location.origin;

  const { commitEphemeralKeyPair, getEphemeralKeyPair } = useKeylessAccounts();

  const ephemeralKeyPair = useMemo(() => {
    let keyPair = getEphemeralKeyPair();

    // If no key pair is found, create a new one and commit it to the store
    if (!keyPair) {
      keyPair = createEphemeralKeyPair({
        expiryDateSecs: Number(BigInt(Math.floor(Date.now() / 1000)) + BigInt(60 * 60 * 24 * 30)),
      });
      // keyPair = createEphemeralKeyPair();
      commitEphemeralKeyPair(keyPair);
    }

    return keyPair;
  }, [commitEphemeralKeyPair, getEphemeralKeyPair]);

  useEffect(() => {
    if (provider) {
      localStorage.setItem('oauth_provider', provider);
      if (provider === 'GOOGLE') {
        const redirectUrl = new URL(GOOGLE_AUTH_URL);
        const searchParams = new URLSearchParams({
          client_id: GOOGLE_CLIENT_ID,
          redirect_uri: `${originUrl}/callback`,
          response_type: 'id_token token',
          scope: 'openid email profile',
          nonce: ephemeralKeyPair.nonce,
          prompt: 'select_account',
          provider: 'GOOGLE',
        });
        redirectUrl.search = searchParams.toString();
        window.location.href = redirectUrl.toString();
      } else if (provider === 'APPLE') {
        const redirectUrl = new URL(APPLE_AUTH_URL);
        const searchParams = new URLSearchParams({
          client_id: APPLE_CLIENT_ID,
          redirect_uri: `${originUrl}/auth/apple/callback`,
          response_type: 'code id_token',
          scope: 'openid name email',
          response_mode: 'form_post',
          nonce: ephemeralKeyPair.nonce,
          provider: 'APPLE',
        });
        redirectUrl.search = searchParams.toString();
        window.location.href = redirectUrl.toString();
      }
    }
  }, [APPLE_AUTH_URL, APPLE_CLIENT_ID, GOOGLE_AUTH_URL, GOOGLE_CLIENT_ID, ephemeralKeyPair.nonce, originUrl, provider]);

  return { provider };
};

export default useStartLoginMain;
