import CryptoJS from 'crypto-js';
import { useEffect, useRef, useState, useCallback } from 'react';
import { isIOS, isAndroid } from 'react-device-detect';
import { useNavigate } from 'react-router-dom';

import { useKeylessAccounts } from '~/hooks/useKeylessAccounts';
import { iosIdentifier } from '~/hooks/usePopup';

const useAuthSuccessMain = () => {
  const { activeAccount } = useKeylessAccounts();
  const hasLoggedOnce = useRef(false);
  const [hasLogged, setHasLogged] = useState(false);
  const navigate = useNavigate();

  const encryptKeysArray = useCallback((keysArray: any[]): string => {
    const ENCRYPTION_KEY = import.meta.env.VITE_ENCRYPTION_KEY;
    const IV = import.meta.env.VITE_IV;

    const encryptionKey = CryptoJS.enc.Utf8.parse(ENCRYPTION_KEY);
    const iv = CryptoJS.enc.Utf8.parse(IV);

    const getCircularReplacer = () => {
      const seen = new WeakSet();
      return (_key: string, value: any) => {
        if (typeof value === 'object' && value !== null) {
          if (seen.has(value)) {
            return;
          }
          seen.add(value);
        }
        return value;
      };
    };

    const keysArrayString = JSON.stringify(keysArray, getCircularReplacer());

    const encrypted = CryptoJS.AES.encrypt(keysArrayString, encryptionKey, {
      iv: iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7,
    }).toString();

    return encrypted;
  }, []);

  useEffect(() => {
    if (!hasLoggedOnce.current && !hasLogged && activeAccount && activeAccount.proof) {
      const accountBytes = activeAccount.bcsToBytes();
      const accountArray = Array.from(accountBytes);
      const keylessAccount = encryptKeysArray(accountArray);
      const keylessAccountExpire = activeAccount.ephemeralKeyPair.expiryDateSecs.toString();
      const oauthProvider = localStorage.getItem('oauth_provider');
      const idToken = localStorage.getItem('id_token');
      const accountAddress = activeAccount.accountAddress.toString();

      if (isIOS) {
        // iOS
        window.location.href = `${iosIdentifier}://login?keylessAccount=${keylessAccount}&keylessAccountExpire=${keylessAccountExpire}&oauthProvider=${oauthProvider}&idToken=${idToken}&accountAddress=${accountAddress}`;
      } else if (isAndroid) {
        // Android
        window.location.href = `intent://login?keylessAccount=${keylessAccount}&keylessAccountExpire=${keylessAccountExpire}&oauthProvider=${oauthProvider}&idToken=${idToken}&accountAddress=${accountAddress}#Intent;scheme=milelog;package=com.milelog.dev;end`;
      } else {
        alert('로그인 완료. 모바일 감지가 되지 않아 로그인 페이지로 이동합니다.');
        navigate('/login');
      }
      hasLoggedOnce.current = true;
      setHasLogged(true);
    }
  }, [activeAccount, encryptKeysArray, hasLogged, navigate, activeAccount?.proof]);

  return {};
};

export default useAuthSuccessMain;
