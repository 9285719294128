import { useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';

import { useKeylessAccounts } from '~/hooks/useKeylessAccounts';
import { setErrorMsg } from '~/hooks/usePopup';

const useCallbackMain = () => {
  const isLoading = useRef(false);
  const { activeAccount, switchKeylessAccount } = useKeylessAccounts();
  const navigate = useNavigate();
  let oauthProvider = localStorage.getItem('oauth_provider');

  let queryParams: URLSearchParams | undefined;
  let authorizationCode: string | null = null;

  if (window.location.hash) {
    queryParams = new URLSearchParams(window.location.hash.substring(1));
    oauthProvider = 'GOOGLE';
    localStorage.setItem('oauth_provider', 'GOOGLE');
  } else if (window.location.search) {
    queryParams = new URLSearchParams(window.location.search);
    authorizationCode = queryParams.get('code');
    oauthProvider = 'APPLE';
    localStorage.setItem('oauth_provider', 'APPLE');
  }

  const idToken = queryParams?.get('id_token');
  const accessToken = queryParams?.get('access_token');

  useEffect(() => {
    if (activeAccount) {
      navigate('/auth-success');
    }
  }, [activeAccount, navigate]);

  useEffect(() => {
    if (isLoading.current) return;
    isLoading.current = true;

    async function deriveAccount(idToken: string, oauthProvider: string, authorizationCode?: string) {
      try {
        await switchKeylessAccount(idToken);

        if (oauthProvider === 'GOOGLE' && accessToken) {
          localStorage.setItem('access_token', accessToken);
        } else if (oauthProvider === 'APPLE' && authorizationCode) {
          localStorage.setItem('authorization_code', authorizationCode);
        }

        if (idToken) {
          localStorage.setItem('id_token', idToken);
        }
        navigate('/auth-success');
      } catch (error) {
        console.error('Failed to derive account:', error);
        isLoading.current = false;
        setErrorMsg('로그인 과정이 중단되었습니다. 다시 시도해 주세요. (오류코드: deriveAccount)');
      }
    }

    if (!idToken || !oauthProvider || (oauthProvider === 'APPLE' && !authorizationCode)) {
      isLoading.current = false;
      setErrorMsg('로그인 과정이 중단되었습니다. 다시 시도해 주세요. (오류코드: idToken)');
      return;
    }

    deriveAccount(idToken, oauthProvider, authorizationCode ?? undefined);
  }, [idToken, authorizationCode, accessToken, isLoading, navigate, switchKeylessAccount, oauthProvider]);

  return {};
};

export default useCallbackMain;
