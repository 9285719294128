import useStartLoginMain from './useStartLoginMain';
import Loader from '../../Loader';

const StartLoginMain = () => {
  useStartLoginMain();

  return (
    <div className="flex items-center justify-center h-screen w-screen">
      <Loader />
    </div>
  );
};

export default StartLoginMain;
