import { isIOS, isAndroid } from 'react-device-detect';

export const iosIdentifier =
  import.meta.env.VITE_NODE_ENV === 'production'
    ? 'milelog'
    : import.meta.env.VITE_NODE_ENV === 'staging'
      ? 'milelogstaging'
      : 'milelogdev';

export const setErrorMsg = (errorMsg: string) => {
  if (isIOS) {
    // iOS
    window.location.href = `${iosIdentifier}://login?errorMsg=${errorMsg}`;
  } else if (isAndroid) {
    // Android
    window.location.href = `intent://login?errorMsg=${errorMsg}#Intent;scheme=milelog;package=com.milelog.dev;end`;
  } else {
    alert(errorMsg);
  }
};
